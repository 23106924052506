import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import styles from "./index.module.less"
import Layout from "../components/Layout"
import Img from "gatsby-image"
import getYouTubeID from "get-youtube-id"
import sortPodcasts from "../utils/sortPodcasts"

const IndexPage = ({ data }) => {
  const pageData = data.pageData.nodes[0]

  const [podcasts, setPodcasts] = useState([])

  useEffect(() => {
    setPodcasts(sortPodcasts(data.podcasts.edges))
  }, [data.podcasts.edges])

  const isExtLink = link => {
    return link.includes("http")
  }

  // console.log(podcasts.map(item => item.node.postDate))

  return (
    <Layout
      meta={{
        title: pageData.metaTitle,
        description: pageData.metaDescription.metaDescription,
        image: pageData.metaImage.localFile.publicURL,
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-10 col-lg-9">
            <h1 className={`h2 ${styles.home_page_title}`}>{pageData.title}</h1>
            {isExtLink(pageData.heroButtonLink) ? (
              <a
                href={pageData.heroButtonLink}
                className="btn btn-primary"
                target={"_blank"}
                rel={"noreferrer"}
              >
                {pageData.heroButtonLabel}
              </a>
            ) : (
              <Link to={pageData.heroButtonLink} className="btn btn-primary">
                {pageData.heroButtonLabel}
              </Link>
            )}
          </div>
        </div>
      </div>

      <div className={`container`}>
        <div className={styles.home_image_grid}>
          <Img fluid={pageData.introImage.localFile.childImageSharp.fluid} />
        </div>

        <hr className={styles.home_hr} />
      </div>

      <div className="container">
        <h2>Latest episodes</h2>

        <div className="row">
          {podcasts.slice(0, 3).map((episode, idx) => {
            return <Podcast episode={episode} key={idx} />
          })}
        </div>
      </div>

      <div className="container">
        <hr className={styles.home_hr} />
        <div className="row">
          <div className="col-sm-12">
            <h2>
              The Immigrants of Canada podcast is hosted by Suzette Smith.
            </h2>
            <div className={"youtube-video"}>
              <iframe
                src={`https://www.youtube-nocookie.com/embed/${pageData.youTubeVideoId}`}
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                title={pageData.videoSectionTitle}
                allowFullScreen
              />
            </div>
          </div>
        </div>
        <hr className={styles.home_hr} />
      </div>

      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-10 col-lg-8">
            <h2 className={styles.intro_title}>{pageData.introTitle}</h2>
            {pageData.introText.introText.split("\n").map((text, idx) => (
              <p key={idx}>{text}</p>
            ))}
          </div>
        </div>

        <hr className={styles.home_hr} />
      </div>

      <div className="container">
        <div className={styles.home_footer}>
          <Img
            fluid={pageData.ctaBackgroundImage.localFile.childImageSharp.fluid}
            alt=""
            style={{ position: "absolute" }}
          />
          <div>
            <h2>
              Like the stories you’re hearing? Immigrants of Canada is an
              independent organization that is completely funded by people like
              you.
            </h2>
            {isExtLink(pageData.ctaButtonLink) ? (
              <a href={pageData.ctaButtonLink}>{pageData.ctaButtonLabel}</a>
            ) : (
              <Link className={"btn btn-primary"} to={pageData.ctaButtonLink}>
                {pageData.ctaButtonLabel}
              </Link>
            )}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage

export const Podcast = ({
  episode: {
    node: {
      title,
      description: { description },
      youTubeVideoLink,
    },
  },
}) => {
  return (
    <div className={"col-12 col-sm-6 col-lg-4"}>
      <a
        href={youTubeVideoLink}
        target={"_blank"}
        rel={"noreferrer"}
        className={styles.podcast_block}
      >
        <div className={"youtube-video my-0"}>
          <iframe
            src={`https://www.youtube-nocookie.com/embed/${getYouTubeID(
              youTubeVideoLink
            )}?rel=0`}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            title={""}
            allowFullScreen
          />
        </div>
        <h4>{title}</h4>
        <p>{description}</p>
      </a>
    </div>
  )
}

export const query = graphql`
  query {
    pageData: allContentfulHomePage {
      nodes {
        title
        heroButtonLink
        heroButtonLabel
        introImage {
          localFile {
            publicURL
            childImageSharp {
              fluid(maxWidth: 1440) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        videoSectionTitle
        youTubeVideoId
        introTitle
        introText {
          introText
        }
        ctaButtonLabel
        ctaButtonLink
        ctaText {
          ctaText
        }
        ctaBackgroundImage {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1440) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }

        metaTitle
        metaDescription {
          metaDescription
        }
        metaImage {
          localFile {
            publicURL
          }
        }
      }
    }
    podcasts: allContentfulPodcast {
      edges {
        node {
          title
          description {
            description
          }
          slug
          postDate
          youTubeVideoLink
        }
      }
    }
  }
`
